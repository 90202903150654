<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="1">
        <v-btn fab small color="accent" to="/admin/marketing/push/">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <h4 class="title">List of Push Messages</h4>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="list" :items-per-page="15">
          <template v-slot:item.message="{ item }">
            {{ item.subject | truncate(50) }}
          </template>

          <template v-slot:item.createdTime="{ item }">
            {{ item.createdTime | formatDateTime }}
          </template>

          <template v-slot:item.id="{ item }">
            <router-link :to="'push/' + item.id"> {{ item.id }}</router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: 'Status',

          value: 'status'
        },
        { text: 'Message', value: 'message' },
        { text: 'Date', value: 'createdTime' },

        { text: 'View', value: 'id' }
      ],
      list: []
    }
  },
  mounted() {
    this.$store.dispatch('loadPushMessages').then(list => {
      if (list) {
        list.forEach(d => {
          this.list.push({ ...d.data(), id: d.id })
        })
      }
    })
  }
}
</script>
